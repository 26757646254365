import {
  removeLeadingTrailingChar,
  tryDecodeURIComponent,
} from 'common/helpers';
import { saveAs } from 'file-saver';

import { auth } from '@/firebase';
import API from '@/services/API';
import { serializeData } from '@/services/tools';
import { Roles } from '@/types';
import { getLocalData } from '@/utils/localStorage';
import { LOCAL_STORAGE_KEYS } from '@/constants/account';

export const impUser = (headers) => {
  const impUser = JSON.parse(
    localStorage.getItem('customLoginUser') ?? '{}'
  ).uid;
  if (impUser) {
    headers.impuid = impUser;
  }
  return headers;
};

export const addUid = (body) => {
  const impUser = JSON.parse(
    localStorage.getItem('customLoginUser') ?? '{}'
  ).uid;
  const selectedAccountId = getLocalData(
    LOCAL_STORAGE_KEYS.selectedAccount
  )?.accountId;

  if (impUser && !body.uid) {
    body.uid = impUser;
  } else if (!impUser && !body.uid) {
    body.uid = auth?.currentUser?.uid;
  }

  if (selectedAccountId && !body.accountId) {
    body.account_id = selectedAccountId;
  }
  return body;
};

export const floorMinute = (ms) => {
  const date = new Date(ms);
  date.setSeconds(0, 0);
  return date.getTime();
};

export const floorHour = (ms) => {
  const date = new Date(ms);
  date.setMinutes(0, 0, 0);
  return date.getTime();
};

export const floorDay = (ms) => {
  const date = new Date(ms);
  date.setHours(0);
  date.setMinutes(0, 0, 0);
  return date.getTime();
};

/**
 * Read file as array buffer
 * @param {File} file file
 * @returns {Promise<ArrayBuffer>} array buffer
 */
export const readFile = async (file) => {
  if (!file) {
    throw new Error('No file given');
  }
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      const error = 'Encountered error uploading document';
      reject(error);
    };
    reader.onload = () => {
      if (reader.result instanceof ArrayBuffer) {
        resolve(reader.result);
      }
    };
    reader.readAsArrayBuffer(file);
  });
};

export const arrayBufferToBase64 = (buffer) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i += 1) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

export const exportCsv = (headers, data, filename) => {
  const csvLines = [];
  // Headers can be flat array of fieldIds or array of header objects
  const headerLabels = headers?.map((header) => {
    let headerLabel = header;
    if (typeof header === 'object') {
      if (header.getKey instanceof Function) {
        headerLabel = header.getKey();
      } else {
        headerLabel = header.id;
        if (header.id2) {
          headerLabel = header.id2;
        }
      }
    }
    return headerLabel;
  });
  csvLines.push(headerLabels?.join(','));
  if (typeof data === 'string') {
    csvLines.push(data.replace(',', ''));
  } else {
    for (const dataRow of data) {
      const csvLine = [];
      for (const [index, header] of headers.entries()) {
        let headerLabel = header;
        let aggregateKey;
        if (typeof header === 'object') {
          if (header.getKey instanceof Function) {
            headerLabel = header.getKey();
          } else {
            headerLabel = header.id;
            if (header.id2) {
              headerLabel = header.id2;
              aggregateKey = header.id;
            }
          }
        }
        let value = aggregateKey
          ? dataRow?.[aggregateKey]?.[headerLabel]?.[aggregateKey]
          : dataRow[headerLabel]
            ? dataRow[headerLabel]
            : dataRow[index];
        if (header.getter instanceof Function) {
          value = header.getter(value);
        }
        if (header.formatter instanceof Function) {
          value = header.formatter(value);
        }
        if (typeof value === 'string') {
          // TODO: Add more complete escaping
          value = value.replace(/\n/g, ' ');
          if (value.includes(',') || value.includes('"')) {
            value = `"${value}"`;
          }
        }
        csvLine.push(value ?? '');
      }
      csvLines.push(csvLine?.join(','));
    }
  }
  const csvText = csvLines?.join('\n');
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    `data:text/plain;charset=utf-8,${encodeURIComponent(csvText)}`
  );
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

/**
 * Download the reconciliations as csv
 * @param {{
 * page?: number|string;
 * limit?:number;
 * q?:string;
 * orderBy?:string;
 * sort?:string,
 * start?: Date | string;
 * end?: Date|string;
 * extraParams?: any;
 * }} data query options
 * @param {{idToken:string; endpoint: 'reconciliation_data' | 'statment_data' | 'report_data' | 'saved_reports' | 'custom_download'; exportOptions?: {}}} option authentication token
 * @returns csv file data
 */
export const exportToCsv = async (data, option) => {
  const extraParams = data.extraParams;

  let _search = serializeData({
    q: data.q,
    orderBy: data.orderBy,
    sort: data.sort,
    endpoint: option.endpoint,
    exportOptions: JSON.stringify(option.exportOptions),
  });

  if (extraParams) {
    for (const [key, value] of extraParams.entries()) {
      _search += `&${key}=${value}`;
    }
  }

  const res = await fetch(
    `${process.env.REACT_APP_API}/api/export?${_search}`,
    {
      method: 'GET',
      headers: await API.getHeaders(),
    }
  );

  if (!res.ok) {
    return res.json().then((data) => {
      throw new Error(data.error);
    });
  }

  const contentType = res.headers.get('Content-Type');
  const _filename = res.headers
    .get('Content-Disposition')
    ?.split('filename=')?.[1];
  const filename = tryDecodeURIComponent(_filename || '');
  let extension;

  switch (contentType) {
    case 'application/zip':
      extension = '.zip';
      break;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      extension = '.xlsx';
      break;
    case 'application/pdf':
      extension = '.pdf';
      break;
    default:
      extension = '.csv';
  }

  const blob = await res.blob();
  let fileDownloadName = '';
  // @ts-ignore
  if (option?.exportOptions?.fileName) {
    // @ts-ignore
    fileDownloadName = `${option.exportOptions.fileName}${extension}`;
  } else if (filename) {
    fileDownloadName = removeLeadingTrailingChar(filename, '"');
  } else {
    fileDownloadName = `${option.endpoint}${extension}`;
  }

  saveAs(blob, fileDownloadName);
};

export const getMimeType = (fileName) => {
  const extension = fileName.split('.').pop().toLowerCase();
  switch (extension) {
    case 'xlsx':
    case 'xls':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    case 'pdf':
      return 'application/pdf';
    case 'png':
      return 'image/png';
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'csv':
      return 'text/csv';
    case 'txt':
      return 'text/plain';
    default:
      return 'application/octet-stream'; // default to binary data
  }
};

/**
 * Get file data from url
 * @param {string} fileUrl
 * @returns {Promise<File>} file
 */
export async function getFileData(fileUrl, filename) {
  const response = await fetch(fileUrl);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  const contentType = getMimeType(filename);

  const data = await response.blob();
  const file = new File([data], filename, { type: contentType });
  file.path = filename;
  return file;
}

export const convertArrayOfMapsToArrayOfArrays = (
  fields = [],
  arrayOfMaps = [{}]
) => {
  const arr = [];
  arrayOfMaps?.forEach((map) => {
    const row = [];
    fields?.forEach((k) => {
      row.push(map[k]);
    });
    arr.push(row);
  });
  return arr;
};

export const convertFieldListToMapping = (fields) => {
  const mapping = {};
  fields.forEach((field, i) => {
    mapping[field] = i;
  });
  return mapping;
};

export const mapToArray = (obj) => {
  return Object.values(obj)?.map((item) => {
    const isObj = Object.prototype.toString.call(item) === '[object Object]';
    if (isObj) {
      return Object.values(item);
    }
    return item;
  });
};

// export const mapToArray = (obj) => {
//   return Object.values(obj).map((item) => (typeof item === 'object' ? Object.values(item) : item));
// }

export const arrayOfArraysToArrayOfMaps = (arr) => {
  const result = [];
  const keys = arr[0];
  arr.slice(1).forEach((row) => {
    const obj = {};
    keys.forEach((key, i) => {
      obj[key] = row[i];
    });
    result.push(obj);
  });
  return {
    fields: keys,
    data: result,
  };
};

// In the full data set, split out the rows of the same length into their own separate arrays.
export const splitByLength = (data) => {
  const results = [];
  let curLength = 0;
  let curArray = [];
  let header = [];
  let headerIndex = 0;
  data.forEach((row, i) => {
    if (row.length !== curLength) {
      if (curArray.length > 0) {
        results.push({
          headerIndex,
          dataStartIndex: headerIndex + 1,
          dataEndIndex: i - 1,
          data: curArray,
        });
      }
      curArray = [];
      curLength = row.length;
    }
    if (JSON.stringify(row) !== JSON.stringify(header)) {
      if (curArray.length === 0) {
        header = row;
        headerIndex = i;
      }
      curArray.push(row);
    } else {
      //
    }
  });
  if (curArray.length > 0) {
    results.push(curArray);
  }
  return results;
};

export const hasAccess = (acl, userRole, isFintaryAdmin) => {
  if (!acl) return true; // !acl = no restrictions added
  const _acl = Array.isArray(acl) ? acl : [acl];
  if (
    (_acl.includes('admin') || _acl.includes(Roles.FINTARY_ADMIN)) &&
    isFintaryAdmin
  )
    return true;
  return acl.includes(userRole);
};

export const requiresFintaryAdmin = (acl) => {
  const _acl = Array.isArray(acl) ? acl : [acl];
  return _acl.includes('admin') || _acl.includes(Roles.FINTARY_ADMIN);
};

/**
 * Check the input value type is the same as the type
 * @param {*} val
 * @param {String|Number|Boolean|Object|Date} _type
 */
export function checkType(val, _type) {
  // string, number, boolean, array, object, null, undefined, symbol, function Date, RegExp, Error, Promise, Map, Set, Math, JSON
  const type = Object.prototype.toString.call(val);
  return type === `[object ${_type}]`;
}

/**
 * Removes specified query parameters from the current URL.
 *
 * @param {string[]} parameters - An array of query parameter names to remove.
 */
export const removeURLQueryParameters = (parameters) => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  parameters.forEach((param) => params.delete(param));

  url.search = params.toString();
  window.history.pushState({}, '', url.toString());
};
