import {
  Add,
  Download,
  ExpandLessOutlined,
  ExpandMoreOutlined,
  FilterAltOffOutlined,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Link,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { numberOrDefault } from 'common/helpers';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { isEqual } from 'lodash-es';
import log from 'loglevel';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import BasicDateRangePicker from '@/common/BasicDateRangePicker';
import DataForm from '@/components/DataForm';
import LoadingCircle from '@/components/atoms/LoadingCircle';
import BasicDatePicker from '@/components/molecules/BasicDatePicker';
import EnhancedTable from '@/components/molecules/EnhancedTable';
import MoreDateFilters from '@/components/molecules/MoreDateFilters';
import MoreMenu from '@/components/molecules/MoreMenu';
import MultiSelect from '@/components/molecules/MultiSelect';
import SaveReport from '@/components/molecules/SaveReport';
import SearchBox from '@/components/molecules/SearchBox';
import SearchSettings from '@/components/molecules/SearchSettings';
import SplitButton from '@/components/molecules/SplitButton';
import { auth } from '@/firebase';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import { exportToCsv } from '@/services/helpers';
import { isNill } from '@/services/tools';
import { useMenuStore } from '@/store';
import useAccountStore from '@/store/accountStore';
import { DocumentPreviewKeys } from '@/types';
import useSnackbar from '@/contexts/useSnackbar';
import { EnhancedSelect } from '../molecules/EnhancedSelect';

dayjs.extend(utc);

const EnhancedDataView = ({
  dataSpec,
  setSelectedData = (a) => {},
  options = { mode: 'default' },
  prefilter = undefined,
  suggested = undefined,
  filters = null,
  hideAdd = false,
  hideExport = false,
  hideSelectedCount = false,
  enableSaves = false,
  exportOptions = [],
  defaultData = {},
  reportId = null,
  showTotals = false,
  readOnly = false,
  rowKey = '',
  actionsEnabled = () => false,
  actions = [],
  outstandingMobileFields = [],
  enableMultiSelect = true,
  enableEdit = true,
  enableResetFilters = true,
  nonSelectableOnMobile = false,
  onBulkSync = undefined,
  extraActions = [],
  variant = '',
  extraFormActions = [],
  onQueryKeyChange = (queryKey) => {},
}) => {
  const { menuOpen } = useMenuStore();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [collapseFilterBar, setCollapseFilterBar] = useState(true);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const prevSearchParams = useRef(searchParams.toString());
  const mode = searchParams.get('m') ?? 'list';
  const rowsPerPage = numberOrDefault(searchParams.get('limit'), 50);
  const page = numberOrDefault(searchParams.get('page'), 0);
  const [newData, setNewData] = useState(defaultData || {});
  const [oldData, setOldData] = useState(defaultData || {});
  const [existingFieldOptions, setExistingFieldOptions] = useState({});
  const [availableFilterValues, setAvailableFilterValues] = useState({});
  const [orderBy, setOrderBy] = useState(
    dataSpec.defaultOrderBy ?? 'created_at'
  );
  const [order, setOrder] = useState(dataSpec.defaultSort ?? 'desc');
  const [filterSuggested, setFilterSuggested] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [savingReport, setSavingReport] = useState(false);
  const [initialFilterValues, setInitialFilterValues] = useState();
  const [fields, setFields] = useState(
    Object.values(dataSpec?.fields)
      .filter((f) => f.label)
      .filter((f) => !f.label?.includes('🔒'))
      .filter((f) => !f.defaultTableHidden || f.type !== 'dynamic-select') // dynamic-select can't be filtered out or hooks rendering rules will fail
      .map((f) => f.label)
  );
  const [snapshotData, setSnapshotData] = useState({});
  const [getDataUrl, setGetDataUrl] = useState('');
  const { data: accountInfo } = API.getBasicQuery('accounts');
  const { selectedAccount } = useAccountStore();
  const { showSnackbar } = useSnackbar();

  const qcParam = searchParams.get('qc');
  const idParam = searchParams.get('id');
  const includeZeroCommissionParam =
    searchParams.get('incl_zero_commissions') === 'true';

  const reconciliationThreshold = accountInfo?.reconciliation_threshold ?? 1;

  const poster = API.getMutation(dataSpec.table, 'POST');
  const patcher = API.getMutation(dataSpec.table, 'PATCH');
  const bulkPatcher = API.getMutation(`${dataSpec.table}/bulk_edit`, 'PATCH');
  const deleter = API.getMutation(dataSpec.table, 'DELETE');

  // TODO (frank.santillan): Fix commission_filters. Probably better for us to just move onto the next version of configuring these, so will just comment this out for now.
  // useEffect(() => {
  //   if (accountSettings?.commissions_filters?.length > 0) {
  //     accountSettings.commissions_filters.forEach((_filter) => {
  //       const filter = _filter.filter((item) => item);
  //       if (!filter) {
  //         console.warn('Invalid filter', filter);
  //         return;
  //       }
  //       const filterNameValue = filter.split('::');
  //       setSearchParams((prev) => {
  //         prev.set(filterNameValue[0], filterNameValue[1]);
  //         return prev;
  //       });
  //     });
  //   }
  // }, [accountSettings]);

  useEffect(() => {
    const currentSearchParams = new URLSearchParams(searchParams.toString());
    const oldSearchParams = new URLSearchParams(prevSearchParams.current);

    const inPreview =
      currentSearchParams.get('m') === DocumentPreviewKeys.PREVIEW;
    if (inPreview) {
      return;
    }
    currentSearchParams.delete('page');
    oldSearchParams.delete('page');

    if (currentSearchParams.toString() !== oldSearchParams.toString()) {
      handleChangePage('', 0);
    }

    prevSearchParams.current = searchParams.toString();
  }, [searchParams]);

  // Add cleanup function for form data
  useEffect(() => {
    return () => {
      // Clean up form data when component unmounts
      setNewData({});
      setOldData({});
    };
  }, []);

  const queryKey = [
    selectedAccount?.accountId,
    dataSpec.table,
    page,
    rowsPerPage,
    orderBy,
    order,
    Array.from(searchParams.entries()).toString(),
  ];

  const filterKey = [selectedAccount?.accountId, dataSpec.table];

  const updateSearchParams = (kvMap) =>
    setSearchParams((prev) => {
      Object.entries(kvMap).forEach(([k, v]) => {
        if ([undefined, null].includes(v)) {
          prev.delete(k);
        } else {
          prev.set(k, v);
        }
      });
      return prev;
    });

  const {
    isLoading,
    isError,
    data: queryData,
    refetch,
  } = useQuery({
    queryKey,
    queryFn: async () => {
      let queryParams = `?page=${page}&limit=${rowsPerPage}&orderBy=${orderBy}&sort=${order}`;
      if (reportId) {
        queryParams += `&comp_report_id=${reportId}`;
      }
      let additionalQueryParams = `&${searchParams.toString()}`;
      if (dataSpec.queryChips && qcParam) {
        const chipQuery = dataSpec.queryChips[qcParam]?.query ?? {};
        Object.entries(chipQuery).forEach(([k, v]) => {
          if (v instanceof Array) {
            v.forEach((e) => {
              additionalQueryParams += `&${k}=${encodeURIComponent(e)}`;
            });
          } else {
            additionalQueryParams += `&${k}=${encodeURIComponent(v)}`;
          }
        });
      }
      const url = `${process.env.REACT_APP_API}/api/${dataSpec.table}${queryParams}${additionalQueryParams}`;
      setGetDataUrl(url);
      const res = await fetch(url, {
        method: 'GET',
        headers: await API.getHeaders(),
      });
      const data = await res.json();
      if (data.success === false) {
        throw new Error(data.message);
      }
      return data;
    },
    enabled: !!auth?.currentUser,
  });

  useEffect(() => {
    onQueryKeyChange(queryKey);
  }, [queryKey, onQueryKeyChange]);

  useEffect(() => {
    if (isError) {
      showSnackbar('Error retrieving data, please try again later', 'error');
    }
  }, [isError, showSnackbar]);

  // useEffect(() => {
  //   setInitialFilterValues(null);
  // }, [queryKey]);

  const data = useMemo(() => {
    if (Array.isArray(queryData)) {
      return queryData;
    }
    if (Array.isArray(queryData?.data)) {
      return queryData.data;
    }
    return [];
  }, [queryData]);

  const count = useMemo(() => queryData?.count ?? 0, [queryData?.count]);
  const fieldOptions = useMemo(
    () => (filters || queryData?.fieldOptions) ?? {},
    [filters, queryData?.fieldOptions]
  );
  const totals = useMemo(() => queryData?.totals ?? {}, [queryData?.totals]);
  useEffect(() => {
    if (
      mode === 'edit' &&
      idParam &&
      Array.isArray(data) &&
      data.length === 1
    ) {
      setNewData(data[0]);
      setOldData(JSON.parse(JSON.stringify(data[0])));
    }
  }, [data, mode, idParam]);

  const setFieldsStorage = (newFields) => {
    setFields(newFields);
    if (Array.isArray(newFields) && newFields.length > 0) {
      localStorage.setItem(`ui${location.pathname}`, newFields.join(','));
    }
  };

  useEffect(() => {
    if (isLoading || isEqual(fieldOptions, existingFieldOptions)) {
      return;
    }
    setExistingFieldOptions(fieldOptions);
    const availableVals = {};
    const filteredVals = {};
    Object.entries(fieldOptions).forEach(([k, v]) => {
      if (k.endsWith('_date_start')) {
        availableVals[k] = {
          label: 'Start date',
          type: 'date',
          field: k.replace('_start', ''),
          value: v,
        };
        filteredVals[k] = v;
      } else if (k.endsWith('_date_end')) {
        availableVals[k] = {
          label: 'End date',
          type: 'date',
          field: k.replace('_end', ''),
          value: v,
        };
        filteredVals[k] = v;
      } else if (Array.isArray(v) && v.length) {
        availableVals[k] = { label: k, type: 'multiSelect', options: v };
        filteredVals[k] = Array.isArray(v) ? v : [];
      } else if (k.startsWith('payment_date')) {
        // do nothing
      } else {
        console.warn('Unexpected field option', k, v);
      }
    });
    setAvailableFilterValues(availableVals);
    // setFilteredValues(filteredVals);
    // if (!initialFilterValues) {
    setInitialFilterValues(filteredVals);
    // }
  }, [dataSpec.table, existingFieldOptions, fieldOptions, isLoading]);

  /**
   * Download CSV
   */
  const downloadCsvFn = useCallback(
    async (options = {}) => {
      const idToken = await auth.currentUser?.getIdToken(true);

      let additionalQueryParams = '';

      if (dataSpec.queryChips && qcParam) {
        const chipQuery = dataSpec.queryChips[qcParam]?.query ?? {};
        Object.entries(chipQuery).forEach(([k, v]) => {
          if (v instanceof Array) {
            v.forEach((e) => {
              additionalQueryParams += `&${k}=${encodeURIComponent(e)}`;
            });
          } else {
            additionalQueryParams += `&${k}=${encodeURIComponent(v)}`;
          }
        });
      }

      const tempQuery = {};
      Object.entries(availableFilterValues).forEach(([k, v]) => {
        if (
          v.type === 'multiSelect' &&
          searchParams.getAll(k).length > 0 &&
          // v.options.length !== filteredValues[k].length
          v.options.length !== searchParams.getAll(k).length
        ) {
          tempQuery[k] = searchParams.getAll(k);
        }

        if (v.type === 'date' && searchParams.get(k)) {
          tempQuery[k] = new Date(
            encodeURIComponent(
              new Date(searchParams.get(k)).toISOString().substring(0, 10)
            )
          );
        }
      });
      // Special case for ReconciliationView special casing reconciled status
      // TODO: These should be read directly from Reconciliations.js. And the reconciled statuses should be converted to enums.
      if (dataSpec.table === 'reconciliation_data') {
        tempQuery.reconciled =
          dataSpec.queryChips[qcParam ?? 'all'].query.reconciled;
      }

      if (Object.entries(options).length > 0) {
        Object.entries(options).forEach(([k, v]) => {
          tempQuery[k] = v;
        });
      }
      // TODO: Handle dupes
      // if (fieldOptions.payment_date_first && fieldOptions.payment_date_last) {
      //   tempQuery.payment_date_first = new Date(
      //     encodeURIComponent(
      //       new Date(fieldOptions.payment_date_first)
      //         .toISOString()
      //         .substring(0, 10)
      //     )
      //   );
      //   tempQuery.payment_date_last = new Date(
      //     encodeURIComponent(
      //       new Date(fieldOptions.payment_date_last)
      //         .toISOString()
      //         .substring(0, 10)
      //     )
      //   );
      // }
      try {
        await exportToCsv(
          {
            orderBy,
            sort: order,
            q: additionalQueryParams,
            extraParams: searchParams,
            ...tempQuery,
          },
          { idToken, endpoint: dataSpec.table }
        );
      } catch (err) {
        showSnackbar(err?.message || 'Export failed', 'error');
      }
    },
    [
      availableFilterValues,
      fieldOptions,
      orderBy,
      order,
      dataSpec.table,
      searchParams.toString(),
    ]
  );

  const handleDownload = async (options) => {
    setIsDownloading(true);
    await downloadCsvFn(options);
    setIsDownloading(false);
  };

  const handleChangePage = async (event, newPage) => {
    setSearchParams((prev) => {
      if (newPage && +newPage > 0) {
        prev.set('page', newPage);
      } else {
        prev.delete('page');
      }
      return prev;
    });
  };

  const handleChangeRowsPerPage = (e) => {
    setSearchParams((prev) => {
      prev.delete('page');
      prev.set('limit', e.target.value);
      return prev;
    });
  };

  const dataDesc = {
    label: dataSpec.label,
    table: dataSpec.table,
    copyable: dataSpec.copyable ?? false,
    editable: true,
    fields: Object.entries(dataSpec.fields)
      .filter(([k, v]) => v.enabled)
      .reduce((acc, [k, v]) => [...acc, { ...v, id: k }], []),
  };

  const deleteRows = async (ids) => {
    log.debug('Deleting ids: ', ids);
    await deleter.mutateAsync({ ids });
    setTimeout(refetch, 300);
  };

  useEffect(() => {
    const savedFields = localStorage
      .getItem(`ui${location.pathname}`)
      ?.split(',');
    if (Array.isArray(savedFields) && savedFields.length > 0) {
      setFields(savedFields);
    } else if (Object.keys(dataSpec?.fields ?? {})?.length) {
      setFields(
        Object.values(dataSpec?.fields)
          .filter((f) => f.label)
          .filter((f) => !f.label?.includes('🔒'))
          .map((f) => f.label)
      );
    }
  }, [dataSpec?.fields]);

  const headers = useMemo(() => {
    const newHeaders = Object.entries(dataSpec.fields)
      .map(([k, v]) => ({
        ...v,
        id: k,
      }))
      .filter((i) => i.enabled)
      .filter((i) => (options.mode === 'reconciler' ? i.reconciler : true))
      .filter((i) => fields.includes(i.label));

    const contacts = searchParams.getAll('contacts');

    if (contacts.length === 1) {
      const exportOption = exportOptions.find(
        (option) => option.id === 'export-producer-view'
      );
      if (exportOption) {
        exportOption.options.disabled = false;
      }
    } else {
      const exportOption = exportOptions.find(
        (option) => option.id === 'export-producer-view'
      );
      if (exportOption) {
        exportOption.options.disabled = true;
      }
    }

    // TODO: Find a better way of handling this

    // Figure out first and last day of payments, rather than the filters set in this view
    // queryData?.data?.forEach((row) => {
    //   if (row.payments) {
    //     const payments = Object.keys(row.payments);
    //     const firstPayment = payments[0];
    //     const lastPayment = payments[payments.length - 1];
    //     if (firstPayment) {
    //       const firstPaymentDate = dayjsUTC(firstPayment);
    //       if (
    //         !filteredValues?.effective_date_start ||
    //         firstPaymentDate.isBefore(filteredValues?.effective_date_start)
    //       ) {
    //         setFilteredValues({
    //           ...filteredValues,
    //           effective_date_start: firstPaymentDate.toDate(),
    //         });
    //       }
    //     }
    //     if (lastPayment) {
    //       const lastPaymentDate = dayjsUTC(lastPayment);
    //       if (
    //         !filteredValues?.effective_date_end ||
    //         lastPaymentDate.isAfter(filteredValues?.effective_date_end)
    //       ) {
    //         setFilteredValues({
    //           ...filteredValues,
    //           effective_date_end: lastPaymentDate.toDate(),
    //         });
    //       }
    //     }
    //   }
    // });
    // TODO: This is very specific for commissions. Generalize somehow outside of this component.

    if (dataSpec?.fields?.commission_amount_monthly?.enabled) {
      const startEffective = dayjs.utc(
        searchParams.get('effective_date_start')
      );
      const startPayment = dayjs.utc(fieldOptions.payment_date_first);
      const endEffective = dayjs.utc(searchParams.get('effective_date_end'));
      const endPayment = dayjs.utc(fieldOptions.payment_date_last);
      const start = startEffective.isBefore(startPayment)
        ? startEffective.startOf('month')
        : startPayment.startOf('month');
      const end = endEffective.isAfter(endPayment)
        ? endEffective.startOf('month')
        : endPayment.startOf('month');
      let maxMonths = 60; // 5 years max
      for (let i = end; i >= start; i = i.subtract(1, 'month')) {
        newHeaders.push({
          id: 'commission_amount_monthly',
          id2: i.format('MM/DD/YYYY'),
          label: i.format('MMM YYYY'),
          // formatter: Formatter.currency,
          numeric: true,
          getter: (row) => {
            const month = i.format('MM/DD/YYYY');
            let commissionReceived =
              row.commission_amount_monthly?.[month]?.commission_amount_monthly;
            let commissionExpected = row.commission_expected_monthly?.[month];
            let commissionBalance = row.commission_balance_monthly?.[month];
            // Aggregate child values into parent
            // TODO: Unlink when showing child policies, will ahve to do with normal headers too
            if (row?.children_reconciliation_data?.length > 0) {
              let childrenCommissionReceived;
              let childrenCommissionExpected;
              let childrenCommissionBalance;
              row.children_reconciliation_data.forEach((child) => {
                if (
                  child.commission_amount_monthly?.[month]
                    ?.commission_amount_monthly
                )
                  childrenCommissionReceived =
                    +child.commission_amount_monthly?.[month]
                      ?.commission_amount_monthly +
                    (childrenCommissionReceived ?? 0);
                if (child.commission_expected_monthly?.[month])
                  childrenCommissionExpected =
                    +child.commission_expected_monthly?.[month] +
                    (childrenCommissionExpected ?? 0);
                if (child.commission_balance_monthly?.[month])
                  childrenCommissionBalance =
                    child.commission_balance_monthly?.[month] +
                    (childrenCommissionBalance ?? 0);
              });
              if (childrenCommissionReceived)
                commissionReceived =
                  childrenCommissionReceived + (commissionReceived ?? 0);
              if (childrenCommissionExpected)
                commissionExpected =
                  childrenCommissionExpected + (commissionExpected ?? 0);
              if (childrenCommissionBalance)
                commissionBalance =
                  childrenCommissionBalance + (commissionBalance ?? 0);
            }

            let result = Formatter.currency(commissionReceived);
            let commissionDiff;
            if (!isNill(commissionExpected) || !isNill(commissionReceived)) {
              // const effCommissionReceived = commissionReceived ?? 0;
              // const balance = commissionBalance;
              commissionDiff = (
                <div>
                  Due for this month: {Formatter.currency(commissionExpected)}
                  {commissionBalance < 0 &&
                    Math.abs(commissionBalance) > reconciliationThreshold && (
                      <>
                        <br />
                        <span>
                          Excess: {Formatter.currency(commissionBalance)}
                        </span>
                      </>
                    )}
                </div>
              );
            }
            if (
              commissionBalance < 0 &&
              Math.abs(commissionBalance) > reconciliationThreshold &&
              commissionReceived
            ) {
              result = (
                <Tooltip title={commissionDiff}>
                  <div className="whitespace-nowrap text-black text-right">
                    {Formatter.currency(commissionReceived)}*
                    <br />
                    <Typography variant="caption" className="invisible">
                      Bal: {Formatter.currency(commissionBalance ?? 0)}{' '}
                    </Typography>
                  </div>
                </Tooltip>
              );
            } else if (
              commissionBalance > 0 &&
              Math.abs(commissionBalance) > reconciliationThreshold
            ) {
              result = (
                <Tooltip title={commissionDiff}>
                  <div className="whitespace-nowrap text-black text-right">
                    {Formatter.currency(commissionReceived ?? 0)}
                    <br />
                    <Typography variant="caption" className="text-red-600">
                      Bal: {Formatter.currency(commissionBalance ?? 0)}{' '}
                    </Typography>
                  </div>
                </Tooltip>
              );
            }
            return (
              <div className="whitespace-nowrap text-black text-right">
                {result}
              </div>
            );
          },
        });
        maxMonths -= 1;
        if (maxMonths <= 0) break;
      }
    }
    return newHeaders;
  }, [
    dataSpec.fields,
    searchParams.toString(),
    fieldOptions?.payment_date_first,
    fieldOptions?.payment_date_last,
    options.mode,
    fields,
  ]);

  const searchSettings = [];

  // TODO: Move these filters into dataSpec
  if (
    ['report_data', 'reconciliation_data', 'statement_data'].includes(
      dataSpec.table
    )
  ) {
    searchSettings.unshift({
      id: 'incl_dupes',
      type: 'toggle',
      label: 'Show duplicates',
    });
    searchSettings.unshift({
      id: 'incl_linked',
      type: 'toggle',
      label: `Show linked ${dataSpec.table === 'report_data' ? 'policies' : 'commissions'}`,
    });
  }
  if (dataSpec.table === 'statement_data') {
    searchSettings.unshift({
      id: 'incl_zero_commissions',
      type: 'toggle',
      label: 'Show payouts of $0',
    });
    searchSettings.unshift({
      id: 'hide_no_payout_calc_commissions',
      type: 'toggle',
      label: 'Hide commissions without payout calculated',
    });
  }

  const dataAll = data;

  let dataFiltered = dataAll;

  // TODO: Reconcile prefilter vs suggested, xor for now
  if (prefilter instanceof Function) {
    dataFiltered = dataAll.filter(prefilter);
  } else if (suggested && filterSuggested) {
    dataFiltered = dataAll.filter((e) => suggested.includes(e.policy_id));
  }

  useEffect(() => {
    if (headers.length > 0 && dataFiltered.length > 0)
      setSnapshotData({
        headers: headers,
      });
  }, [dataFiltered, headers]);

  const getValues = (paramKey) => {
    const paramValues = searchParams.getAll(paramKey) || [];
    // Unselect all
    if (paramValues.length === 1 && paramValues[0] === 'undefined') {
      return [];
    }

    return paramValues.length
      ? paramValues
          .map((val) =>
            availableFilterValues[paramKey]?.options.find(
              (option) => String(option.id) === val
            )
          )
          .filter((item) => !!item)
      : availableFilterValues[paramKey]?.options;
  };

  if (!dataSpec) return null;

  return (
    <Box
      sx={{
        width:
          options.mode === 'reconciler'
            ? 'calc((100vw - 200px)/2 - 120px)'
            : `calc(100vw - ${menuOpen ? '200px' : '0px'})`,
      }}
    >
      {options.mode === 'reconciler' ? (
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ height: 56 }}
          >
            <Typography variant="h5">{dataSpec.labelSimple}</Typography>
            <SearchBox id={dataSpec.table} />
          </Box>
        </Box>
      ) : (
        <Box sx={{ pt: 2 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexWrap={isMobile ? 'wrap' : 'nowrap'}
            sx={{ mb: 1, px: 2 }}
          >
            <Typography variant="h5">
              {!['tabbed'].includes(variant) && dataSpec.label}
            </Typography>
            {/* TODO: Re-enable card view */}
            {/* <IconButton
              onClick={() => setView(view === 'table' ? 'cards' : 'table')}
            >
              {view === 'table' ? <Splitscreen /> : <GridOn />}
            </IconButton> */}
            <Box sx={{ display: 'flex' }}>
              {idParam &&
                (idParam.split(',').length === 1 ? (
                  <Chip
                    label={idParam}
                    sx={{ mr: 1 }}
                    onDelete={() => {
                      setOldData({});
                      setNewData({});
                      updateSearchParams({ id: null, m: null });
                    }}
                    color="primary"
                  />
                ) : (
                  <Chip
                    label={`${idParam.split(',')[0]} and ${idParam.split(',').length - 1} others`}
                    sx={{ mr: 1 }}
                    onDelete={() => {
                      updateSearchParams({ id: null, m: null });
                    }}
                    color="primary"
                  />
                ))}
              <SearchBox id={dataSpec.table} />
              <SearchSettings settings={searchSettings} />
            </Box>
          </Box>
          <Box
            sx={{
              scrollbarWidth: 'none',
              height: collapseFilterBar && isMobile ? 46 : 'auto',
              overflowY: collapseFilterBar ? 'hidden' : 'auto',
              position: isMobile ? 'relative' : 'static',
            }}
            className="hiddenScrollbar"
          >
            {isMobile && (
              <Button
                onClick={() => setCollapseFilterBar(!collapseFilterBar)}
                sx={{ position: 'absolute', right: 4, top: 4 }}
                endIcon={
                  collapseFilterBar ? (
                    <ExpandMoreOutlined />
                  ) : (
                    <ExpandLessOutlined />
                  )
                }
              >
                Filters
              </Button>
            )}
            <Box
              display="flex"
              justifyContent={isMobile ? 'start' : 'space-between'}
              alignItems="center"
              sx={{ px: 2, flexWrap: isMobile ? 'wrap' : 'nowrap' }}
            >
              {dataSpec?.queryChips &&
                Object.keys(dataSpec?.queryChips).length > 0 && (
                  <Box
                    sx={{
                      whiteSpace: 'wrap',
                      display: 'flex',
                      width: isMobile ? '100%' : 'auto',
                      justifyContent: 'start',
                      alignItems: 'center',
                      mb: isMobile ? 1.5 : 0,
                    }}
                  >
                    {Object.entries(dataSpec?.queryChips ?? {})
                      .filter(([k, v]) => !v.more)
                      .map(([k, chip]) => (
                        <Chip
                          label={chip.label}
                          key={chip.id}
                          onClick={() => {
                            setSearchParams((prev) => {
                              if (chip.id === 'all') {
                                prev.delete('qc');
                              } else {
                                prev.set('qc', chip.id);
                              }
                              return prev;
                            });
                          }}
                          sx={{ mr: 0.5, cursor: 'pointer' }}
                          color={
                            searchParams.get('qc') === chip.id ||
                            (!searchParams.get('qc') && chip.id === 'all')
                              ? 'primary'
                              : 'default'
                          }
                          variant={
                            searchParams.get('qc') === chip.id ||
                            (!searchParams.get('qc') && chip.id === 'all')
                              ? 'filled'
                              : 'outlined'
                          }
                        />
                      ))}
                    {Object.values(dataSpec?.queryChips ?? {}).filter(
                      (chip) => chip.more
                    ).length > 0 && (
                      <MoreMenu
                        actions={Object.values(dataSpec?.queryChips ?? {})
                          .filter((chip) => chip.more)
                          ?.map((chip) => ({
                            label: chip.label,
                            onClick: () => {
                              setSearchParams((prev) => {
                                if (chip.id === 'all') {
                                  prev.delete('qc');
                                } else {
                                  prev.set('qc', chip.id);
                                }
                                return prev;
                              });
                            },
                          }))}
                        data={null}
                        setActionLoading={() => {}}
                      />
                    )}
                  </Box>
                )}
              <Box
                sx={{
                  mt: 1,
                  mb: isMobile ? 0 : 1,
                  display: 'flex',
                  flexWrap: isMobile ? 'wrap' : 'nowrap',
                  alignItems: 'center',
                }}
              >
                {Object.entries(availableFilterValues)
                  .filter(([k, v]) => v.type === 'date')
                  .map(([k, v]) => (
                    <Tooltip
                      key={v.label}
                      title={
                        ['report_data', 'reconciliation_data'].includes(
                          dataSpec.table
                        )
                          ? 'Effective date'
                          : dataSpec.table === 'statement_data'
                            ? 'Payment date'
                            : ''
                      }
                      placement="top"
                    >
                      <div>
                        <BasicDatePicker
                          label={v.label}
                          key={v.label}
                          value={
                            searchParams.get(k) || initialFilterValues?.[k]
                          }
                          setValue={(e) => {
                            setSearchParams((prev) => {
                              prev.set(k, e);
                              return prev;
                            });
                          }}
                          sx={{ mx: 0.5, width: 142 }}
                        />
                      </div>
                    </Tooltip>
                  ))}
                {Array.isArray(dataSpec?.dateFilters) &&
                  dataSpec?.dateFilters.length > 0 &&
                  Object.keys(availableFilterValues).length > 0 && (
                    <MoreDateFilters
                      title="Additional date filters"
                      filters={dataSpec?.dateFilters ?? []}
                      values={searchParams}
                      onSetValue={(k, e) => {
                        setSearchParams((prev) => {
                          if (e === null || e === undefined || e === '')
                            prev.delete(k);
                          else prev.set(k, e);
                          return prev;
                        });
                      }}
                    />
                  )}
              </Box>
              <Box
                sx={{
                  mt: isMobile ? 1 : 0,
                  display: 'flex',
                  flexWrap: isMobile ? 'wrap' : 'nowrap',
                  alignItems: 'center',
                }}
              >
                {Object.entries(availableFilterValues)
                  .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                  .filter(([k, v]) => v.type === 'multiSelect')
                  .map(([k, v]) =>
                    // For handling objects as options the option must have an 'id' and a 'name' property
                    availableFilterValues[k]?.options.every(
                      (option) => typeof option === 'object'
                    ) ? (
                      dataSpec.filters[k]?.useEnhancedSelect ? (
                        <EnhancedSelect
                          key={k}
                          sx={{ minWidth: 120, width: 'fit-content' }}
                          label={dataSpec.filters[k]?.label}
                          enableSearch
                          multiple
                          options={availableFilterValues[k]?.options}
                          value={getValues(k)}
                          onChange={(values) => {
                            const ids = values.map((item) => item.id);
                            const options = availableFilterValues[k]?.options;

                            // Select all
                            if (!values.length) {
                              setSearchParams((prev) => {
                                prev.set(k, 'undefined');
                                return prev;
                              });
                            } else {
                              setSearchParams((prev) => {
                                prev.delete(k);
                                // If select all values, we remove params on the url instead
                                if (ids.length !== options.length) {
                                  ids.forEach((v) => {
                                    prev.append(k, v);
                                  });
                                }
                                return prev;
                              });
                            }
                          }}
                        />
                      ) : (
                        <MultiSelect
                          label={dataSpec.filters[k]?.label}
                          values={availableFilterValues[k]?.options}
                          key={k}
                          selectedValues={
                            searchParams.getAll(k).length
                              ? searchParams
                                  .getAll(k)
                                  .map(
                                    (id) =>
                                      availableFilterValues[k]?.options.find(
                                        (option) => String(option.id) === id
                                      )?.id
                                  )
                              : availableFilterValues[k]?.options.map(
                                  (option) => option.id
                                ) // Display the 'name' property
                          }
                          setSelectedValues={(values) => {
                            setSearchParams((prev) => {
                              prev.delete(k);

                              if (
                                availableFilterValues[k]?.options.length !==
                                values.length
                              ) {
                                values.forEach((v) => {
                                  if (v !== '') {
                                    prev.append(k, v);
                                  } else {
                                    values.forEach((v) => {
                                      prev.append(k, v);
                                    });
                                  }
                                });
                              }
                              return prev;
                            });
                          }}
                          sx={{ my: 1, mx: 0.5, width: 105 }}
                          enableSearch
                          paginate
                          formatter={(option) => option?.name ?? option?.label}
                          valuer={(option) => option?.id}
                        />
                      )
                    ) : dataSpec.filters[k]?.useEnhancedSelect ? (
                      <EnhancedSelect
                        key={k}
                        sx={{ minWidth: 'fit-content' }}
                        label={dataSpec.filters[k]?.label}
                        enableSearch
                        multiple
                        options={availableFilterValues[k]?.options}
                        value={
                          searchParams.getAll(k).length
                            ? searchParams.getAll(k)
                            : availableFilterValues[k]?.options
                        }
                        onChange={(values) => {
                          setSearchParams((prev) => {
                            prev.delete(k);
                            // If select all values, we remove params on the url instead
                            if (values.length !== options.length) {
                              values.forEach((v) => {
                                prev.append(k, v);
                              });
                            }
                            return prev;
                          });
                        }}
                      />
                    ) : (
                      <MultiSelect
                        label={dataSpec.filters[k]?.label}
                        values={availableFilterValues[k]?.options}
                        key={k}
                        selectedValues={
                          searchParams.getAll(k).length
                            ? searchParams.getAll(k)
                            : availableFilterValues[k]?.options
                        }
                        setSelectedValues={(values) => {
                          setSearchParams((prev) => {
                            prev.delete(k);
                            if (
                              availableFilterValues[k]?.options.length !==
                              values.length
                            ) {
                              values.forEach((v) => {
                                prev.append(k, v);
                              });
                            }
                            return prev;
                          });
                        }}
                        sx={{ my: 1, mx: 0.5, width: 105 }}
                        enableSearch
                        paginate
                      />
                    )
                  )}
                {Object.keys(availableFilterValues).length > 0 &&
                  enableResetFilters &&
                  searchParams.size > 0 && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Tooltip title="Clear filters">
                        <IconButton
                          onClick={() => {
                            setSearchParams({});
                          }}
                          disabled={mode === 'edit'}
                          sx={{ mr: 1 }}
                        >
                          <FilterAltOffOutlined />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
              </Box>
              <Box
                sx={{
                  pr: 2,
                  my: 1,
                  display: 'flex',
                  flexWrap: isMobile ? 'wrap' : 'nowrap',
                  gap: 1,
                  alignItems: 'center',
                }}
              >
                <MultiSelect
                  label="Fields"
                  values={Object.values(dataSpec?.fields)
                    .filter((f) => f.label)
                    .map((f) => f.label)}
                  selectedValues={fields}
                  setSelectedValues={(values) => setFieldsStorage(values)}
                  sx={{ mx: 0.5, maxWidth: 140 }}
                />
                {extraActions.map((action) => (
                  <Box key={action.label}>
                    {action.type === 'button' && (
                      <Button
                        key={action.label}
                        onClick={action.onClick}
                        sx={{ mr: 1 }}
                        endIcon={action.icon}
                        variant={action.variant || 'outlined'}
                        disabled={action.disabled || false}
                        disableElevation
                      >
                        {action.label}
                      </Button>
                    )}
                    {action.type === 'select' && (
                      <Select
                        key={action.label}
                        value={action.value}
                        onChange={action.onChange}
                        sx={{ mr: 1 }}
                      >
                        {action.options.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                    {action.type === 'date' && (
                      <BasicDatePicker
                        key={action.label}
                        label={action.label}
                        value={action.value}
                        setValue={action.onChange}
                        sx={{ mr: 1 }}
                      />
                    )}
                    {action.type === 'dateRange' && (
                      <BasicDateRangePicker
                        key={action.label}
                        range={action.value}
                        onChange={(range) => {
                          setSearchParams((prev) => {
                            prev.delete('date_range');
                            const { startDate, endDate } = range;
                            if (startDate || endDate) {
                              prev.set(
                                'date_range',
                                [startDate, endDate].toString()
                              );
                            }
                            return prev;
                          });
                          action.onChange && action.onChange(range);
                        }}
                      />
                    )}
                    {action.type === 'multiSelect' && (
                      <MultiSelect
                        key={action.label}
                        label={action.label}
                        values={action.options}
                        selectedValues={action.value}
                        setSelectedValues={action.onChange}
                        sx={{ mr: 1 }}
                      />
                    )}
                    {action.element && action.element}
                  </Box>
                ))}
                {enableSaves && (
                  <SaveReport
                    reportId={reportId}
                    queryData={snapshotData}
                    getDataUrl={getDataUrl}
                    table={dataSpec.table}
                    setSavingReport={setSavingReport}
                  />
                )}
                {!hideAdd && (
                  <Button
                    startIcon={<Add />}
                    variant="contained"
                    onClick={() => {
                      updateSearchParams({ m: 'edit', id: null });
                    }}
                    disabled={mode === 'edit'}
                    disableElevation
                  >
                    Add
                  </Button>
                )}
                {!hideExport && (
                  <SplitButton
                    startIcon={<Download />}
                    useLoadingBtn
                    loading={isDownloading}
                    options={
                      exportOptions.length > 0
                        ? exportOptions.map((e) => ({
                            ...e,
                            onClick: () => handleDownload(e.options),
                          }))
                        : [
                            {
                              id: 'export',
                              label: 'Export',
                              onClick: handleDownload,
                            },
                          ]
                    }
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {suggested?.length > 0 && (
        <Box sx={{ textAlign: 'center' }}>
          {filterSuggested ? (
            <Typography variant="body2">
              ✨ Showing suggested matches (
              <Link
                onClick={() => setFilterSuggested(false)}
                sx={{ cursor: 'pointer' }}
              >
                see all
              </Link>
              )
            </Typography>
          ) : (
            <Typography variant="body2">
              Showing all matches (
              <Link
                onClick={() => setFilterSuggested(true)}
                sx={{ cursor: 'pointer' }}
              >
                show suggested
              </Link>
              )
            </Typography>
          )}
        </Box>
      )}

      {!isLoading && mode === 'edit' && (
        <Box
          sx={{
            overflowY: 'scroll',
            height: 'calc(100vh - 168px)',
            display: 'flex',
            justifyContent: 'center',
            p: 2,
          }}
        >
          <DataForm
            dataDesc={dataDesc}
            fields={Object.values(dataDesc.fields).filter((field) =>
              field.condition ? field.condition(newData) : true
            )}
            newData={newData}
            readOnly={readOnly}
            oldData={oldData}
            setNewData={setNewData}
            onCancel={() => {
              setNewData({});
              updateSearchParams({ m: null, id: null });
            }}
            onSave={async () => {
              const normalizedNewData = Object.fromEntries(
                Object.entries(newData).map(([key, value]) => [
                  key,
                  dataDesc.fields.find((field) => field.id === key)?.normalizer
                    ? dataDesc.fields
                        .find((field) => field.id === key)
                        .normalizer(value, [])
                    : value,
                ])
              );
              if (includeZeroCommissionParam)
                normalizedNewData.incl_zero_commissions =
                  includeZeroCommissionParam;

              let res;
              try {
                if (normalizedNewData.id) {
                  if (reportId) normalizedNewData.comp_report_id = reportId;
                  res = await patcher.mutateAsync(normalizedNewData);
                } else {
                  res = await poster.mutateAsync(normalizedNewData);
                }
                setNewData({});
                updateSearchParams({ m: null, id: null });
              } catch (e) {
                console.error('Error encountered while saving data');
                return {
                  error: e.message || 'Error encountered while saving data',
                };
              } finally {
                setTimeout(refetch, 300);
              }
              return res;
            }}
            onDelete={async () => {
              deleter.mutate({ ids: [newData.id] });
              setNewData({});
              updateSearchParams({ m: null, id: null });
            }}
            validateData={dataDesc.validateData}
            extraActions={extraFormActions}
            currentData={newData}
          />
        </Box>
      )}
      <Divider />
      {(isLoading || savingReport) && (
        <Box sx={{ textAlign: 'center', mt: 6 }}>
          <LoadingCircle />
        </Box>
      )}
      {!isLoading &&
        mode !== 'edit' &&
        dataFiltered.length > 0 &&
        headers.length > 0 && (
          <EnhancedTable
            dense
            headers={headers}
            rows={dataFiltered}
            readOnly={readOnly}
            actions={actions}
            outstandingFieldsInMobileView={
              outstandingMobileFields?.length > 0
                ? outstandingMobileFields
                : (dataSpec.outstandingFieldsInMobileView ?? [])
            }
            actionsEnabled={actionsEnabled}
            onDelete={enableMultiSelect ? deleteRows : false}
            nonSelectableOnMobile={nonSelectableOnMobile}
            rowKey={rowKey}
            onBulkSync={onBulkSync}
            onEdit={
              enableEdit
                ? options.mode !== 'reconciler'
                  ? (row) => {
                      updateSearchParams({ m: 'edit', id: row.str_id });
                      setNewData(row);
                      setOldData(JSON.parse(JSON.stringify(row)));
                    }
                  : undefined
                : false
            }
            onBulkEdit={async (selected, updateData) => {
              const response = await bulkPatcher.mutateAsync({
                ids: selected,
                ...updateData,
              });
              setTimeout(refetch, 300);
            }}
            // TODO: Should be controlled selection...hack for now
            setSelectedData={setSelectedData}
            stickyHeader
            paginated
            controlledOrdering={{
              order,
              orderBy,
              setOrder: (e) => {
                handleChangePage(e, 0);
                setOrder(e);
              },
              setOrderBy: (e) => {
                handleChangePage(e, 0);
                setOrderBy(e);
              },
            }}
            controlledPagination={{
              count, // +(filterVals.showDupes ? dataCounts.countDupes : 0),
              page,
              onPageChange: handleChangePage,
              rowsPerPage,
              onRowsPerPageChange: handleChangeRowsPerPage,
            }}
            options={{ hideSelectedCount, radio: options.radio }}
            showTotals={showTotals}
            totals={totals}
            refetch={refetch}
          />
        )}
      {!isLoading && dataFiltered.length === 0 && (
        <Box sx={{ textAlign: 'center', mt: 6, width: '100%' }}>
          {isError ? (
            <Box>
              <Typography variant="h5">
                Error retrieving data, please try again later
              </Typography>
              <Button
                variant="outlined"
                onClick={(_e) => refetch()}
                sx={{ mt: 5 }}
              >
                Retry
              </Button>
            </Box>
          ) : (
            <Typography variant="h5">No results</Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default EnhancedDataView;
