import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { AccountIds } from 'common/constants';
import { nanoid } from 'nanoid';
import { useEffect, useMemo, useState } from 'react';
import { ContactStatuses } from 'common/globalTypes';

import { FilterSelect } from '@/common';
import BasicDatePicker from '@/components/molecules/BasicDatePicker';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import { useAccountStore } from '@/store';

type ContactsReferralsAddProps = {
  data: any;
  setter: any;
  field: any;
  dynamicSelects: any;
};

const defaultReferral = {
  config: {
    rate: '',
    deductFromUpline: false,
    useLifeRatesForAnnuity: true,
    annuity_rate: '',
  },
  end_date: null,
  notes: '',
  remarks: '',
  start_date: null,
  type: '',
};

const ContactsReferralsAdd: React.FC<ContactsReferralsAddProps> = ({
  data,
  field,
  setter,
}) => {
  const contactReferrals = useMemo(() => data?.contact_referrals ?? [], [data]);
  const [showInactiveAgents, setShowInactiveAgents] = useState(false);
  const { selectedAccount } = useAccountStore();
  const {
    data: _contacts,
    isLoading: isLoadingContacts,
    refetch: refetchContacts,
  } = API.getBasicQuery(
    'contacts',
    `is_dynamic_select=true&show_inactive=${showInactiveAgents}`
  );

  useEffect(() => {
    const hasInactiveAgents = contactReferrals.some(
      (referral) =>
        referral.referrer?.status === ContactStatuses.INACTIVE ||
        referral.payer?.status === ContactStatuses.INACTIVE
    );

    if (hasInactiveAgents) {
      setShowInactiveAgents(true);
    }
  }, [contactReferrals]);

  useEffect(() => {
    refetchContacts();
  }, [refetchContacts, showInactiveAgents]);

  const contacts = useMemo(
    () =>
      (_contacts?.data ?? [])
        .map((contact) => ({
          value: contact.id,
          label: Formatter.contact(contact, {
            incl_email: true,
            account_id: selectedAccount?.accountId,
          }),
        }))
        .sort((a, b) => a.label?.localeCompare(b.label)),
    [_contacts?.data, selectedAccount?.accountId]
  );

  const renderCustomOption = () => {
    return (
      selectedAccount?.accountId === AccountIds.TRANSGLOBAL && (
        <FormControlLabel
          control={
            <Checkbox
              checked={showInactiveAgents}
              onChange={(e) => {
                setShowInactiveAgents(e.target.checked);
              }}
            />
          }
          label="Show inactive agents"
          sx={{ ml: 1, minWidth: 270 }}
          onClick={() => {
            setShowInactiveAgents(!showInactiveAgents);
          }}
        />
      )
    );
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="subtitle2">{field.label}</Typography>
      <Box
        key={`${field.id}-box`}
        sx={{
          width: '100%',
          mb: 1,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            mt: 0.5,
            px: 1,
            pt: 0.5,
            pb: 0,
            display: 'flex',
            flexDirection: 'column',
            borderStyle: 'solid',
            borderColor: 'silver',
            borderWidth: 1,
            borderRadius: 4,
            width: '100%',
            backgroundColor: '#2196f308',
          }}
        >
          <Box sx={{ my: 0.5, display: 'flex', flexWrap: 'wrap' }}>
            {contactReferrals.map((referral, index) => (
              <Box
                key={`${referral.id}-${index}`}
                sx={{
                  m: 0.5,
                  p: 1,
                  borderStyle: 'solid',
                  borderColor: 'silver',
                  borderWidth: 1,
                  borderRadius: 4,
                  display: 'inline-block',
                  width: '100%',
                  backgroundColor: '#2196f30a',
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  position="relative"
                  sx={{ ml: 0.5 }}
                >
                  <Typography variant="body2"></Typography>
                </Box>
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'start',
                    }}
                  >
                    <Box>
                      <ToggleButtonGroup
                        color="primary"
                        value={referral.type}
                        exclusive
                        onChange={(e, type) => {
                          setter({
                            ...data,
                            contact_referrals: data.contact_referrals.map(
                              (item) =>
                                item.id === referral.id
                                  ? { ...item, type }
                                  : item
                            ),
                          });
                        }}
                        sx={{ my: 0.5 }}
                      >
                        {[
                          { value: 'agent', label: 'Agent' },
                          { value: 'region', label: 'Region' },
                          { value: 'recruiting', label: 'Recruiting' },
                          { value: 'other', label: 'Other' },
                        ].map((option) => (
                          <ToggleButton
                            value={option.value}
                            key={option.value}
                            sx={{
                              borderRadius: 24,
                              borderColor: '#b9bec2',
                            }}
                          >
                            {option.label}
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                      <BasicDatePicker
                        label="Start date"
                        value={referral.start_date}
                        setValue={(e) => {
                          setter({
                            ...data,
                            contact_referrals: data.contact_referrals.map(
                              (item) =>
                                item.id === referral.id
                                  ? { ...item, start_date: e }
                                  : item
                            ),
                          });
                        }}
                        sx={{ ml: 1, my: 0.5, width: 160 }}
                      />
                      <BasicDatePicker
                        label="End date"
                        value={referral.end_date}
                        setValue={(e) => {
                          setter({
                            ...data,
                            contact_referrals: data.contact_referrals.map(
                              (item) =>
                                item.id === referral.id
                                  ? { ...item, end_date: e }
                                  : item
                            ),
                          });
                        }}
                        sx={{ my: 0.5, ml: 1, width: 160 }}
                      />
                    </Box>
                    <IconButton
                      onClick={() => {
                        setter({
                          ...data,
                          contact_referrals: contactReferrals.filter(
                            (cm) => cm.id !== referral.id
                          ),
                        });
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <Box sx={{ display: 'flex', width: '100%' }}>
                    <FilterSelect
                      label="Payee (recruiting agent)"
                      placeholder="Select agent"
                      options={contacts}
                      value={referral.referrer_contact_id ?? ''}
                      onChange={(e) => {
                        setter({
                          ...data,
                          contact_referrals: data.contact_referrals.map(
                            (item) =>
                              item.id === referral.id
                                ? {
                                    ...item,
                                    referrer_contact_id: e?.data?.value,
                                  }
                                : item
                          ),
                        });
                      }}
                      getOptionLabel={(option: any, options?: any[]) =>
                        typeof option === 'number' && Array.isArray(options)
                          ? (options.find((o) => o.value === option)?.label ??
                            '')
                          : typeof option === 'object'
                            ? option.label
                            : option
                      }
                      sx={{ my: 0.5, width: '100%' }}
                      loading={isLoadingContacts}
                      renderCustomOption={renderCustomOption}
                      enablePagination={true}
                      itemsPerPage={100}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', width: '100%' }}>
                    <FilterSelect
                      label="Payer"
                      placeholder="Select agent"
                      options={contacts}
                      value={referral.payer_contact_id}
                      onChange={(e) => {
                        setter({
                          ...data,
                          contact_referrals: data.contact_referrals.map(
                            (item) =>
                              item.id === referral.id
                                ? {
                                    ...item,
                                    payer_contact_id: e?.data?.value,
                                  }
                                : item
                          ),
                        });
                      }}
                      getOptionLabel={(option: any, options?: any[]) =>
                        typeof option === 'number' && Array.isArray(options)
                          ? (options.find((o) => o.value === option)?.label ??
                            '')
                          : typeof option === 'object'
                            ? option.label
                            : option
                      }
                      sx={{ my: 0.5, width: '100%' }}
                      loading={isLoadingContacts}
                      renderCustomOption={renderCustomOption}
                      enablePagination
                      itemsPerPage={100}
                      disabled={referral.config?.deductFromUpline}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={referral.config?.deductFromUpline}
                          onChange={(e) => {
                            setter({
                              ...data,
                              contact_referrals: data.contact_referrals.map(
                                (item) =>
                                  item.id === referral.id
                                    ? {
                                        ...item,
                                        config: {
                                          ...item.config,
                                          deductFromUpline: e.target.checked,
                                        },
                                        payer_contact_id: null,
                                      }
                                    : item
                              ),
                            });
                          }}
                        />
                      }
                      label="Deduct from agent upline"
                      sx={{ ml: 1, minWidth: 270 }}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', width: '100%' }}>
                    <TextField
                      label="Rate"
                      variant="outlined"
                      value={referral.config?.rate ?? ''}
                      onChange={(e) => {
                        setter({
                          ...data,
                          contact_referrals: data.contact_referrals.map(
                            (item) =>
                              item.id === referral.id
                                ? {
                                    ...item,
                                    config: {
                                      ...item.config,
                                      rate: e.target.value,
                                    },
                                  }
                                : item
                          ),
                        });
                      }}
                      fullWidth
                      multiline
                      error={
                        referral.config?.rate &&
                        Number.isNaN(+referral.config?.rate)
                      }
                      sx={{ my: 0.5 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" sx={{ ml: 0 }}>
                            %
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={referral.config?.useLifeRatesForAnnuity}
                          onChange={(e) => {
                            setter({
                              ...data,
                              contact_referrals: data.contact_referrals.map(
                                (item) =>
                                  item.id === referral.id
                                    ? {
                                        ...item,
                                        config: {
                                          ...item.config,
                                          useLifeRatesForAnnuity:
                                            e.target.checked,
                                        },
                                      }
                                    : item
                              ),
                            });
                          }}
                        />
                      }
                      label="Use life referral rate for annuity"
                      sx={{ ml: 1, minWidth: 270 }}
                    />
                  </Box>
                  {!referral.config?.useLifeRatesForAnnuity && (
                    <Box sx={{ display: 'flex', width: '100%' }}>
                      <TextField
                        label="Annuity rate"
                        variant="outlined"
                        value={referral.config?.annuity_rate ?? ''}
                        onChange={(e) => {
                          setter({
                            ...data,
                            contact_referrals: data.contact_referrals.map(
                              (item) =>
                                item.id === referral.id
                                  ? {
                                      ...item,
                                      config: {
                                        ...item.config,
                                        annuity_rate: e.target.value,
                                      },
                                    }
                                  : item
                            ),
                          });
                        }}
                        fullWidth
                        multiline
                        error={
                          referral.config?.rate &&
                          Number.isNaN(+referral.config?.annuity_rate)
                        }
                        sx={{ my: 0.5 }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end" sx={{ ml: 0 }}>
                              %
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Box sx={{ width: 410 }} />
                    </Box>
                  )}
                  <TextField
                    label="Notes"
                    variant="outlined"
                    value={referral.notes ?? ''}
                    onChange={(e) => {
                      setter({
                        ...data,
                        contact_referrals: data.contact_referrals.map((item) =>
                          item.id === referral.id
                            ? { ...item, notes: e.target.value }
                            : item
                        ),
                      });
                    }}
                    fullWidth
                    multiline
                    sx={{ my: 0.5 }}
                  />
                  <TextField
                    label="Remarks"
                    variant="outlined"
                    value={referral.remarks ?? ''}
                    onChange={(e) => {
                      setter({
                        ...data,
                        contact_referrals: data.contact_referrals.map((item) =>
                          item.id === referral.id
                            ? { ...item, remarks: e.target.value }
                            : item
                        ),
                      });
                    }}
                    fullWidth
                    multiline
                    sx={{ my: 0.5 }}
                  />
                </Box>
              </Box>
            ))}
            <FormControl key={field.id} sx={{ m: 0.5, width: 30 }}>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setter({
                    ...data,
                    contact_referrals: [
                      ...(data.contact_referrals ?? []),
                      { ...defaultReferral, id: nanoid() },
                    ],
                  });
                }}
              >
                Add
              </Button>
            </FormControl>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ContactsReferralsAdd;
