import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { ReconcileDTO } from 'common/dto/reconcilers/dto';
import { useEffect, useMemo, useRef, useState } from 'react';

import { BasicDialog } from '@/common';
import AdminCard from '@/common/AdminCard';
import AdminBox from '@/components/atoms/AdminBox';
import DataView from '@/components/DataView';
import BasicDatePicker from '@/components/molecules/BasicDatePicker';
import MultiSelect from '@/components/molecules/MultiSelect';
import { DataProcessingTaskProgress } from '@/components/SettingsView/DataProcessingTaskProgress';
import { getProcessingLogDataDesc } from '@/dataDesc/processingLogDataDesc';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import { useAccountStore } from '@/store';
import { DataSyncWorker } from '@/components/DataSyncWorker';
import useSnackbar from '@/contexts/useSnackbar';

const deDupeData = async (args) => {
  const res = await fetch(
    `${process.env.REACT_APP_API}/api/data_processing/grouping`,
    {
      method: 'POST',
      headers: await API.getHeaders(),
      body: JSON.stringify(args),
    }
  );
  const resJson = await res.json();
  return resJson;
};

const calcReceivables = async () => {
  const res = await fetch(
    `${process.env.REACT_APP_API}/api/receivables-schedule/calculate`,
    {
      method: 'POST',
      headers: await API.getHeaders(),
    }
  );

  const resJson = await res.json();
  return resJson;
};

const DataProcessing = () => {
  const { selectedAccount } = useAccountStore();
  const [loading, setLoading] = useState({
    agentCommissionCalc: false,
    agentCommissionCalcClear: false,
    dataSync: false,
    group: false,
    match: false,
    receivablesCalc: false,
  });
  const [refresh, setRefresh] = useState(0);
  const [dedupeArgs, setDedupeArgs] = useState({
    policyDataPriorityField: 'created_at',
    isSync: false,
  });
  const [reconcileArgs, setReconcileArgs] = useState<
    ReconcileDTO & { calcReceivables: boolean; groupDedupe: boolean }
  >({
    calcReceivables: true,
    groupDedupe: true,
    flowId: null,
    forceFullRun: false,
    isSync: false,
  });
  const [commissionCalcArgs, setCommissionCalcArgs] = useState({
    contactIds: [],
    dataSet: 'all',
    documentIds: [],
    endDate: null,
    id: '',
    payingEntity: '',
    policyId: '',
    startDate: null,
    useGroupedCommissions: true,
    isSync: false,
    disableNewCalc: false,
  });

  const [taskIds, setTaskIds] = useState<string[]>([]);
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const { data: accountSettings } = API.getBasicQuery(`accounts/settings`);
  const { data: _contacts } = API.getBasicQuery(
    'contacts',
    'is_dynamic_select=true'
  );
  const contacts = _contacts?.data ?? [];
  const { data: _payingEntities } = API.getBasicQuery(
    'statement_data/carrier_name'
  );
  const { data: _documents } = API.getBasicQuery(
    'documents',
    'is_dynamic_select=true'
  );

  const eventListenerRef = useRef<(evt: any) => void | null>(null);

  useEffect(() => {
    return () => {
      // Cleanup function to remove the event listener when component unmounts
      if (eventListenerRef.current) {
        window.removeEventListener('onDone', eventListenerRef.current);
      }
    };
  }, []);

  const processReconciliation = async (options = {}) => {
    const res = await fetch(
      `${process.env.REACT_APP_API}/api/reconciliation/reconcile`,
      {
        method: 'POST',
        headers: await API.getHeaders(),
        body: JSON.stringify(options),
      }
    );
    const data = await res.json();
    if (res.status === 200 && data.statusText) {
      throw new Error(data.statusText);
    } else if (res.status >= 400) {
      throw new Error(data.statusText);
    }
    if (data.taskId) {
      setTaskIds([...taskIds, data.taskId]);
    }
    console.log('processReconciliation: ', data);
    return data;
  };
  const payingEntities = _payingEntities?.values ?? [];
  const documents = _documents?.data ?? [];

  const viewSettings =
    accountSettings?.pages_settings?.settings_data_processing;

  const enableRunReconciliationButton = useMemo(() => {
    if (viewSettings) {
      let pageOptions;
      if (typeof viewSettings.page_options === 'string') {
        pageOptions = JSON.parse(viewSettings.page_options || '{}');
      } else {
        pageOptions = viewSettings.page_options;
      }
      return Array.isArray(pageOptions)
        ? pageOptions.find(
            (option) => option.enable_run_reconciliation_button !== undefined
          )?.enable_run_reconciliation_button
        : true;
    } else {
      return true;
    }
  }, [viewSettings?.page_options]);

  const { showSnackbar } = useSnackbar();

  const agentCommissionsPoster = API.getMutation(
    'data_processing/commissions/agents',
    'POST'
  );
  const agentCommissionsDelete = API.getMutation(
    'data_processing/commissions/agents',
    'DELETE'
  );

  const { data: accountData } = API.getBasicQuery('accounts');
  const { data: workerInfos } = API.getBasicQuery(
    'data_processing/sync/workers',
    `accountId=${selectedAccount?.accountId}`,
    (accountData?.data_processors ?? []).includes('dataSync')
  );
  const processAgentCommissions = async () => {
    const res = await agentCommissionsPoster.mutateAsync(commissionCalcArgs);
    console.log('result: ', res);
    return res;
  };

  const clearAgentCommissions = async () => {
    const res = await agentCommissionsDelete.mutateAsync({});
    console.log('result: ', res);
    return res;
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          mx: 2,
        }}
      >
        <Box>
          <Box>
            <FormControl>
              <FormLabel id="group-data">Group / dedupe</FormLabel>
            </FormControl>
          </Box>
          <AdminCard showIcon iconPosition="right">
            <FormControlLabel
              control={
                <Checkbox
                  checked={!dedupeArgs.isSync}
                  onChange={(e) =>
                    setDedupeArgs({
                      ...dedupeArgs,
                      isSync: !e.target.checked,
                    })
                  }
                />
              }
              label="Use workers"
            />
          </AdminCard>
          <LoadingButton
            loading={loading.group}
            onClick={async () => {
              setLoading({ ...loading, group: true });
              const res = await deDupeData(dedupeArgs);
              if (res.taskId) {
                setTaskIds([...taskIds, res.taskId]);
              }
              setLoading({ ...loading, group: false });
              setRefresh(refresh + 1);
              if (res.success) {
                showSnackbar(
                  'Grouping and deduping task is created',
                  'success'
                );
              } else if (res.statusText === 'ok') {
                showSnackbar('Grouping and deduping completed', 'success');
              } else {
                showSnackbar(
                  `${res.message || 'Error grouping data'}`,
                  'error'
                );
              }
            }}
            variant="contained"
          >
            Group data
          </LoadingButton>
        </Box>
        <Box>
          <FormControl>
            <FormLabel>Reconciliation</FormLabel>
            <FormControlLabel
              control={
                <Checkbox
                  checked={reconcileArgs.groupDedupe}
                  onChange={(e) =>
                    setReconcileArgs({
                      ...reconcileArgs,
                      groupDedupe: e.target.checked,
                    })
                  }
                />
              }
              label="Group / dedupe"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={reconcileArgs.calcReceivables}
                  onChange={(e) => {
                    setReconcileArgs({
                      ...reconcileArgs,
                      calcReceivables: e.target.checked,
                    });
                  }}
                />
              }
              label="Receivables calc"
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    setReconcileArgs({
                      ...reconcileArgs,
                      forceFullRun: e.target.checked,
                    });
                  }}
                />
              }
              label="Full run"
            />
            <AdminCard showIcon iconPosition="right">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!reconcileArgs.isSync}
                    onChange={(e) =>
                      setReconcileArgs({
                        ...reconcileArgs,
                        isSync: !e.target.checked,
                      })
                    }
                  />
                }
                label="Use workers"
              />
            </AdminCard>
            <Tooltip
              title={
                accountData?.default_reconciler
                  ? ''
                  : 'Reconciliation not configured'
              }
            >
              <span>
                <LoadingButton
                  loading={loading.match}
                  onClick={async () => {
                    setLoading({ ...loading, match: true });
                    if (reconcileArgs.groupDedupe) {
                      const ret = await deDupeData({
                        ...dedupeArgs,
                        isSync: reconcileArgs.isSync,
                      });
                      if (!reconcileArgs.isSync) {
                        setTaskIds([...taskIds, ret.taskId]);
                        await new Promise((resolve) => {
                          const listener = (evt: any) => {
                            if (evt?.detail?.id === ret.taskId) {
                              resolve(true);
                              window.removeEventListener('onDone', listener);
                            }
                          };
                          window.addEventListener('onDone', listener, {
                            once: true,
                          });
                        });
                      }
                    }
                    try {
                      await processReconciliation({
                        ...reconcileArgs,
                        deDupe: true,
                        redo: true,
                        testRun: false,
                        flowId: accountData.default_reconciler,
                      });
                    } catch (error: unknown) {
                      showSnackbar(`${(error as Error).message}`, 'error');
                    }
                    if (reconcileArgs.calcReceivables) {
                      const res = await calcReceivables();
                      if (res.success === true) {
                        showSnackbar(
                          'Receivables calculation completed',
                          'success'
                        );
                      } else {
                        showSnackbar(
                          'Error calculating receivables',
                          'error',
                          12000
                        );
                      }
                    }
                    setLoading({ ...loading, match: false });
                    setRefresh(refresh + 1);
                  }}
                  variant="contained"
                  disabled={
                    !accountData?.default_reconciler ||
                    !enableRunReconciliationButton
                  }
                >
                  Reconcile data
                </LoadingButton>
              </span>
            </Tooltip>
          </FormControl>

          <Box sx={{ mt: 2 }}>
            <FormControl>
              <FormLabel id="group-data">Receivables calc</FormLabel>
            </FormControl>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <LoadingButton
              loading={loading.receivablesCalc}
              onClick={async () => {
                try {
                  setLoading({ ...loading, receivablesCalc: true });
                  const res = await calcReceivables();
                  if (res.success === true) {
                    showSnackbar(
                      'Receivables calculation completed',
                      'success'
                    );
                  } else {
                    showSnackbar('Error calculating receivables', 'error');
                  }
                } catch (e: any) {
                  showSnackbar(e.message, 'error');
                } finally {
                  setLoading({ ...loading, receivablesCalc: false });
                  setRefresh(refresh + 1);
                }
              }}
              variant="contained"
            >
              Calculate
            </LoadingButton>
          </Box>
        </Box>
        <Box>
          <Box>
            <FormControl>
              <FormLabel id="agent-commission-calc-settings">
                Comp calc
              </FormLabel>
              <Box>
                <BasicDatePicker
                  label="Start date"
                  value={commissionCalcArgs.startDate}
                  setValue={(e) => {
                    setCommissionCalcArgs({
                      ...commissionCalcArgs,
                      startDate: e,
                    });
                  }}
                  sx={{ mt: 1, width: 160 }}
                />
                <BasicDatePicker
                  label="End date"
                  value={commissionCalcArgs.endDate}
                  setValue={(e) => {
                    setCommissionCalcArgs({
                      ...commissionCalcArgs,
                      endDate: e,
                    });
                  }}
                  sx={{ ml: 1, mt: 1, width: 160 }}
                />
              </Box>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <MultiSelect
                  label="Paying entities"
                  values={payingEntities}
                  selectedValues={commissionCalcArgs.payingEntity.split(',')}
                  setSelectedValues={(values) =>
                    setCommissionCalcArgs({
                      ...commissionCalcArgs,
                      payingEntity: values.join(','),
                    })
                  }
                  sx={{ width: 200, mt: 1.5 }}
                  enableSearch
                  paginate
                />
                <MultiSelect<any, any>
                  label="Agents"
                  values={contacts}
                  valuer={(o) => o.str_id}
                  formatter={(o) =>
                    Formatter.contact(o, {
                      incl_email: true,
                      account_id: selectedAccount?.accountId,
                    })
                  }
                  selectedValues={commissionCalcArgs.contactIds}
                  setSelectedValues={(values) =>
                    setCommissionCalcArgs({
                      ...commissionCalcArgs,
                      // @ts-ignore
                      contactIds: values,
                    })
                  }
                  sx={{ width: 200, mt: 1.5 }}
                  enableSearch
                  paginate
                />
                <MultiSelect<any, any>
                  label="Documents"
                  values={documents}
                  valuer={(o) => o.str_id}
                  formatter={(o) => {
                    return o
                      ? `${o?.filename} (${Formatter.date(o?.created_at, { format: 'YYYY/MM/DD hh:mmA' })})`
                      : '';
                  }}
                  selectedValues={commissionCalcArgs.documentIds}
                  setSelectedValues={(values) =>
                    setCommissionCalcArgs({
                      ...commissionCalcArgs,
                      // @ts-ignore
                      documentIds: values,
                    })
                  }
                  sx={{ width: 200, mt: 1.5 }}
                  enableSearch
                  paginate
                />
              </Box>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <TextField
                  label="Policy ID"
                  value={commissionCalcArgs.policyId}
                  onChange={(e) =>
                    setCommissionCalcArgs({
                      ...commissionCalcArgs,
                      policyId: e.target.value,
                    })
                  }
                  sx={{
                    mt: 1.5,
                    width: 200,
                    '.MuiInputBase-input': {
                      py: 0.75,
                      pl: 1.5,
                    },
                  }}
                  InputLabelProps={{
                    sx: commissionCalcArgs.policyId ? {} : { top: -3 },
                  }}
                />
                <TextField
                  label="Individual record"
                  value={commissionCalcArgs.id}
                  onChange={(e) =>
                    setCommissionCalcArgs({
                      ...commissionCalcArgs,
                      id: e.target.value,
                    })
                  }
                  sx={{
                    mt: 1.5,
                    width: 200,
                    '.MuiInputBase-input': {
                      py: 0.75,
                      pl: 1.5,
                    },
                  }}
                  InputLabelProps={{
                    sx: commissionCalcArgs.id ? {} : { top: -3 },
                  }}
                />
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={commissionCalcArgs.useGroupedCommissions}
                    onChange={(e) =>
                      setCommissionCalcArgs({
                        ...commissionCalcArgs,
                        useGroupedCommissions: e.target.checked,
                      })
                    }
                  />
                }
                label="Use grouped commissions"
              />
              <AdminCard showIcon iconPosition="right">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!commissionCalcArgs.isSync}
                      onChange={(e) =>
                        setCommissionCalcArgs({
                          ...commissionCalcArgs,
                          isSync: !e.target.checked,
                        })
                      }
                    />
                  }
                  label="Use workers"
                />
              </AdminCard>
              <AdminCard showIcon iconPosition="right">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={commissionCalcArgs.disableNewCalc}
                      onChange={(e) =>
                        setCommissionCalcArgs({
                          ...commissionCalcArgs,
                          disableNewCalc: e.target.checked,
                        })
                      }
                    />
                  }
                  label="Disable new calc"
                />
              </AdminCard>
            </FormControl>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <LoadingButton
              loading={loading.agentCommissionCalc}
              onClick={async () => {
                try {
                  setLoading({ ...loading, agentCommissionCalc: true });
                  const res = await processAgentCommissions();
                  if (res.statusText === 'ok' || res.success) {
                    showSnackbar('Agent commission calc completed', 'success');
                    if (res.taskId) {
                      setTaskIds([...taskIds, res.taskId]);
                    }
                  } else {
                    showSnackbar(
                      `${res.message || 'Error calculating agent commissions'} `,
                      'error'
                    );
                  }
                } catch (e: any) {
                  showSnackbar(e.message, 'error');
                } finally {
                  setLoading({ ...loading, agentCommissionCalc: false });
                  setRefresh(refresh + 1);
                }
              }}
              variant="contained"
              sx={{ width: '220px' }}
            >
              Calculate
            </LoadingButton>
            <LoadingButton
              loading={loading.agentCommissionCalcClear}
              onClick={() => setDeleteConfirm(true)}
              variant="outlined"
              sx={{ width: '220px', mt: 1 }}
            >
              Clear ALL results
            </LoadingButton>
          </Box>
        </Box>
        {(accountData?.data_processors ?? []).includes('dataSync') && (
          <Box>
            {workerInfos?.map((workerInfo, idx) => (
              <DataSyncWorker
                key={`${workerInfo.worker}-${idx}`}
                workerInfo={workerInfo}
                onSyncComplete={() => setRefresh(refresh + 1)}
                onSyncStart={(taskId) => setTaskIds([...taskIds, taskId])}
              />
            ))}
          </Box>
        )}
        <Stack spacing={10}>
          {taskIds.map((id) => (
            <DataProcessingTaskProgress
              taskId={id}
              key={id}
              onFinish={() => {
                dispatchEvent(new CustomEvent('onDone', { detail: { id } }));
              }}
              onClose={() => {
                setTaskIds(taskIds.filter((r) => r != id));
              }}
            />
          ))}
        </Stack>
      </Box>
      <BasicDialog
        title="Delete comp calculation results?"
        open={deleteConfirm}
        onClose={async (val) => {
          setDeleteConfirm(false);
          if (val) {
            setLoading({ ...loading, agentCommissionCalcClear: true });
            const res = await clearAgentCommissions();
            setLoading({ ...loading, agentCommissionCalcClear: false });
            setRefresh(refresh + 1);
            if (res.statusText === 'ok') {
              showSnackbar('Agent commission calc cleared', 'success');
            } else {
              showSnackbar('Error clearing agent commissions', 'error');
            }
          } else {
            // Do nothing
          }
        }}
        bodyComponent={
          <Alert severity="warning">
            Are you sure you want to delete all comp calculation results?
            Approved, Manual, and Paid entries will not be cleared.
          </Alert>
        }
      />
      <AdminBox
        expandable
        expandableDefault={false}
        title="Activity logs"
        sx={{ height: 800 }}
      >
        <DataView
          dataDesc={getProcessingLogDataDesc()}
          viewOnly
          hideExport
          refresh={refresh}
          embed
          sx={{ width: '100%' }}
          enablePagination
        />
      </AdminBox>
    </Box>
  );
};

export default DataProcessing;
